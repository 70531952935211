<template>
  <div class="responsive-table">
    <div
      v-if="!contacts.length && fetched"
      class="text-center table-placeholder"
    >
      <i class="far fa-edit font-size-80"></i>
      <h5 class="card-title m-t-20">{{ $t('warnings.no-users') }}</h5>
      <p>{{ $t('warnings.register-user') }}</p>
      <router-link
        to="/contacts"
        v-if="isSendPage"
        type="button"
        class="btn btn-success"
      >
        {{ $t('generic-str.lbl-new-contact') }}
        <i class="icon dripicons-plus"></i>
      </router-link>
    </div>
    <table v-if="fetched" class="table">
      <thead v-if="contacts.length" class="bg-light">
        <tr>
          <th class="w-60" scope="col">
            <div class="custom-control custom-checkbox">
              <input
                v-model="selectAllContacts"
                type="checkbox"
                @change="selectEvent"
                class="custom-control-input"
                id="customCheck1"
              />
              <label class="custom-control-label" for="customCheck1"></label>
            </div>
          </th>
          <th scope="col">{{ $tc('generic-str.name', 1) }}</th>
          <th scope="col">{{ $tc('generic-str.lbl-number', 1) }}</th>
          <th scope="col">Email</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(contact, i) in contacts" :key="contact.id">
          <td data-label>
            <div class="custom-control custom-checkbox">
              <input
                v-model="selectedContacts"
                type="checkbox"
                class="custom-control-input"
                :id="'contact' + i"
                @change="selectEvent"
                :value="contact"
              />
              <label class="custom-control-label" :for="'contact' + i"></label>
            </div>
          </td>
          <td data-label="Nome">{{ contact.first_name }}</td>
          <td data-label="Número">{{ contact.mobile_number | phone }}</td>
          <td data-label="Email">{{ contact.email }}</td>
          <td data-label>
            <div class="dropdown" v-if="!isSendPage">
              <a
                role="button"
                class="dropdown-action"
                :to="'#menu-dropdown-' + i"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="icon dripicons-gear text-success"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                :id="'menu-dropdown-' + i"
                x-placement="bottom-end"
                style
              >
                <a
                  class="dropdown-item emit-j-even"
                  v-modal="{ target: 'contact-modal', data: contact }"
                  @click="edit(contact)"
                  >{{ $t('generic-str.edit') }}</a
                >
                <a class="dropdown-item" @click="remove(contact.id)">{{
                  $t('generic-str.remove')
                }}</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="qt-block-ui relative" style="padding: 120px" />
    <br />
    <pagination :lastPage="pages" @change="fetch" />
  </div>
</template>

<script>
import ContactService from '@/services/contact.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'ContactsTable',
  props: ['isSendPage'],
  components: {
    Pagination,
  },
  data() {
    return {
      fetched: false,
      contacts: [],
      form: {
        q: '',
        page: 1,
      },
      pages: 1,
      selectedContacts: [],
    };
  },
  computed: {
    selectAllContacts: {
      get() {
        if (this.contacts) {
          return this.selectedContacts.length === this.contacts.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.contacts.forEach((contact) => {
            selected.push(contact);
          });
        }

        this.selectedContacts = selected;
      },
    },
  },
  created() {
    this.fetch(1);
    this.$root.$on('contacts.search', this.searchContacts);
  },
  methods: {
    edit(data) {
      this.$root.$emit('contact.modal.change', data);
    },
    refreshSelected(param) {
      this.selectedContacts = param.selectedContacts;
    },
    selectEvent() {
      this.$root.$emit('contacts.selected', {
        selectedContacts: this.selectedContacts,
      });
    },
    getByPage(pageNum) {
      this.fetched = false;
      ContactService.getContacts(pageNum).then(
        (response) => {
          this.contacts = response.data.data;
          this.paginate = response.data;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    searchContacts(param) {
      this.fetched = false;
      this.form.q = param.q;
      this.form.page = 1;
      ContactService.searchContacts(this.form, param.q).then(
        (response) => {
          this.fetched = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      ContactService.getContacts(this.form).then(
        (response) => {
          this.fetched = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('domain.label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ContactService.deleteContact(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                this.$t('contact-list-component.deleted'),
                'success',
              );
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.table-responsive {
  min-height: 200px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
.pagination {
}
.page-item {
}
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
</style>
