<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('generic-str.lbl-new-contact')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="create" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$t('generic-str.lbl-first-name')}}</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.first_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$t('generic-str.lbl-last-name')}}</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.last_name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$t('generic-str.lbl-cellphone')}}</label
                >
                <div class="col-md-6">
                  <tel-input
                    ref="mobile_number"
                    v-if="isShow"
                    v-model="form.mobile_number"
                  />
                  <div class="invalid-feedback" :class="{ show: invalidPhone }">
                    {{$t('login-invite.invalid-phone')}}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >Email</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  />
                </div>
              </div>
              <div v-if="!group" class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$t('sms.send-msg.tabs.groups.title')}}</label
                >
                <div class="col-md-6">
                  <select-input
                    multiple
                    label="name"
                    :reduce="(group) => group.id"
                    v-model="form.groups"
                    :options="groups"
                    :clearable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.create')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ContactService from '@/services/contact.service';
import Modal from '@/mixins/Modal';
import SelectInput from '@/components/SelectInput.vue';
import TelInput from '@/components/form/TelInput.vue';

export default {
  components: {
    SelectInput,
    TelInput,
  },
  mixins: [Modal],
  props: ['id', 'group'],
  data() {
    return {
      groups: [],
      mobile_number: null,
      invalidPhone: false,
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      form: {
        first_name: '',
        last_name: '',
        mobile_number: '',
        email: '',
        groups: [],
      },
      isSending: false,
    };
  },
  created() {
    this.fetchGroups();
  },
  methods: {
    numberChanges(rest) {
      if (rest.number.input.length > 0) {
        this.invalidPhone = !rest.isValid;
        if (rest.isValid) {
          this.mobile_number = rest.number.national;
          this.form.mobile_number = rest.number.input;
        }
      }
    },
    fetchGroups() {
      ContactService.getGroups().then(
        (groups) => {
          this.groups = groups;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    create() {
      if (this.$refs.mobile_number.isValid || this.form.email) {
        this.isSending = true;

        if (this.group) this.form.groups[0] = this.group.id;

        ContactService.createContact(this.form).then(
          () => {
            this.hide();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Contato criado',
              type: 'success',
            });
            this.$emit('sent');
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      } else {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Celular ou email inválido',
          type: 'danger',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
</style>
