<template>
  <div class="responsive-table">
    <div v-if="!groups.length && fetched" class="text-center table-placeholder">
      <i class="fas fa-users font-size-80"></i>
      <h5 class="card-title m-t-20">{{$t('contacts-component.none')}}</h5>
      <p>{{$t('contacts-component.register')}}</p>
      <router-link
        to="/contacts/groups"
        v-if="isSendPage"
        type="button"
        class="btn btn-success"
        >Novo Grupo <i class="icon dripicons-plus"></i
      ></router-link>
    </div>
    <table v-if="fetched" class="table">
      <thead v-if="groups.length" class="bg-light">
        <tr>
          <th class="w-60" scope="col">
            <div class="custom-control custom-checkbox">
              <input
                v-model="selectAllGroups"
                type="checkbox"
                class="custom-control-input"
                id="customCheckGAll"
                @change="selectEvent"
              />
              <label class="custom-control-label" for="customCheckGAll"></label>
            </div>
          </th>
          <th scope="col">{{$tc('generic-str.name', 1)}}</th>
          <th scope="col">{{$tc('sms.send-msg.tabs.contact.title', 2)}}</th>
          <th scope="col">{{$t('generic-str.info')}}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(group, i) in groups" :key="group.id">
          <td data-label="">
            <div class="custom-control custom-checkbox">
              <input
                v-model="selectedGroups"
                @change="selectEvent"
                type="checkbox"
                class="custom-control-input"
                :id="'group-check' + i"
                :value="group"
              />
              <label
                class="custom-control-label"
                :for="'group-check' + i"
              ></label>
            </div>
          </td>
          <td data-label="Nome">{{ group.name }}</td>
          <td data-label="Contatos">{{ group.contacts_count }}</td>
          <td data-label="Informações">{{ group.description }}</td>
          <td data-label="Nome">
            <div class="dropdown" v-if="!isSendPage">
              <a
                role="button"
                class="dropdown-action"
                :to="'#menu-dropdown-' + i"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="icon dripicons-gear text-success"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                :id="'menu-dropdown-' + i"
                x-placement="bottom-end"
                style=""
              >
                <a
                  class="dropdown-item"
                  v-modal="{ target: 'group-modal', data: group }"
                  >{{$t('generic-str.edit')}}</a
                >
                <a class="dropdown-item" @click="remove(group.id)">{{$t('generic-str.remove')}}</a>
                <a class="dropdown-item" @click="contacts(group)">{{$tc('sms.send-msg.tabs.contact.title', 2)}}</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="qt-block-ui relative" style="padding: 120px" />
    <br />
    <pagination :lastPage="pages" @change="fetch" />
  </div>
</template>

<script>
import ContactService from '@/services/contact.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'ContactsTable',
  props: ['isSendPage'],
  components: {
    Pagination,
  },
  data() {
    return {
      fetched: false,
      groups: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      selectedGroups: [],
    };
  },
  computed: {
    selectAllGroups: {
      get() {
        if (this.groups) {
          return this.selectedGroups.length === this.groups.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.groups.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedGroups = selected;
      },
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    refreshSelected(param) {
      this.selectedGroups = param.selectedGroups;
    },
    getByPage(pageNum) {
      this.fetched = false;
      this.fetchGroups(pageNum);
    },
    selectEvent() {
      this.$root.$emit('groups.selected', {
        selectedGroups: this.selectedGroups,
      });
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      ContactService.getGroups(this.form).then(
        (response) => {
          this.fetched = true;
          this.groups = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    contacts(g) {
      this.$router.push({ name: 'GroupContacts', params: { group: g } });
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          'O grupo será excluído permanentemente. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ContactService.deleteGroup(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, 'Seu grupo foi excluído.', 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.table-responsive {
  min-height: 200px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
</style>
