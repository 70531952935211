class Utils {
  isNumber(n) {
    this.a = false;
    this.a = /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    return this.a;
  }

  isMidia(link) {
    this.a = new Map([['jpg', 'jpeg', 'img'], ['gif', 'img'], ['mp4', 'video'], ['3gp', 'video']]);

    const url = new URL(link);
    const extension = url.pathname.split('.')[1];

    return this.a.get(extension);
  }
}
export default new Utils();
