<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload de Imagem</h5>
          <button type="button" class="close" @click="cancelCrop" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body p10">
              <div class="form-group row">
                <div class="col-md-8 col-lg-8" :class="{ 'col-md-8 col-lg-8': showPreview, 'col-md-12 col-lg-12': !showPreview }">
                  <div style="height:45px;" v-if="!showDrop">
                    <button class="btn btn-success to-right no-radius" v-if="!showDrop" @click="cropImage" type="button">{{$t('generic-str.save')}}</button>
                    <button class="btn btn-danger to-right color-white no-radius" v-if="!showDrop" @click="resetCrop" type="button"><i class="fas fa-times color-white"></i></button>
                  </div>
                  <drag-drop :isUnic="true" :callBackFiles="callBackFiles" v-if="showDrop"></drag-drop>
                  <img ref="source"  :class="{ 'hide': showDrop }" :src="objectUrl" style="width: 100%; max-width: 100%; max-height: 350px;">
                </div>
                <div class="col-md-4 col-lg-4" v-if="showPreview">
                  <label class="control-label text-right">Preview</label>
                  <div class="image-cover preview">
                    <img :src="previewCropped" class="image-preview"/>
                  </div>
                </div>
                <div class="col-lg-12" :class="{ 'hide': showDrop }">
                  <label class="control-label text-right to-left"
                    >Zoom</label>
                  <vue-slider v-model="zoom" @change="zoomChange"/>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import debounce from 'lodash/debounce';
import VueSlider from 'vue-slider-component';
import DragDrop from './DragDrop.vue';
import 'vue-slider-component/theme/antd.css';

export default {
  components: {
    DragDrop,
    VueSlider,
  },
  mixins: [Modal],
  props: {
    id: {
      type: String,
    },
    showPreview: {
      type: Boolean,
    },
    callBack: {
      type: Function,
    },
  },
  data() {
    return {
      cropper: null,
      zoom: 0,
      lastZoom: 0,
      srcImage: null,
      objectUrl: null,
      showDrop: true,
      file: null,
      img: null,
      name: null,
      previewCropped: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 257),
      form: {
      },
    };
  },
  mounted() {
    this.resetCrop();
  },
  created() {
    this.resetCrop();
    if (this.data) {
      this.maxWidth = this.data.maxWidth;
      this.maxHeight = this.data.maxHeight;
    }
  },
  methods: {
    zoomChange(value, index) {
      const zoomNow = value / 100;
      console.log(value, index);
      console.log(this.lastZoom);
      console.log(zoomNow);
      if (this.lastZoom > zoomNow) {
        this.cropper.zoom(-(zoomNow + 0.3));
      } else {
        this.cropper.zoom(+zoomNow);
      }

      this.lastZoom = zoomNow;
    },
    resetCrop() {
      this.showDrop = true;
      if (this.cropper) {
        this.cropper.destroy();
      }
    },
    cropImage() {
      const cropImage = this.cropper.getCroppedCanvas().toDataURL();
      const data = this.cropper.getData();
      data.url = cropImage;
      data.file = this.file;
      data.name = this.$store.state.crop.name;
      console.log(this.$store.state.crop.name);
      this.hide(true);
      this.$root.$emit('call.crop', data);
      this.resetCrop();
    },
    cancelCrop() {
      this.hide(true);
      this.$root.$emit('call.crop', null);
    },
    setupCropper(selectedFile) {
      if (this.cropper) {
        this.cropper.destroy();
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
      }

      if (!selectedFile) {
        this.cropper = null;
        this.objectUrl = null;
        this.previewCropped = null;
        return;
      }

      this.objectUrl = window.URL.createObjectURL(selectedFile);
      this.$nextTick(this.setupCropperInstance);
    },
    callBackFiles(files) {
      this.file = files[0];

      const reader = new FileReader();
      // reader.onload = (function (aImg) {
      //   return function (e) {
      //     if (e.target.result != null) {
      //       this.srcImage = e.target.result;
      //     }
      //   };
      // }(this.img));
      reader.onload = (e) => {
        if (!e.target.result) this.srcImage = e.target.result;
      };
      reader.readAsDataURL(files[0]);

      this.setupCropper(files[0]);
      this.showDrop = false;
    },
    updatePreview(event) {
      const canvas = this.cropper.getCroppedCanvas();
      this.previewCropped = canvas.toDataURL('image/png');
    },
    setupCropperInstance() {
      const params = {
        crop: this.debouncedUpdatePreview,
        viewMode: this.$store.state.crop.viewMode,
        dragMode: 'move',
        cropBoxMovable: false,
        autoCropArea: 1,
        zoom: -10,
        movable: true,
        zoomable: true,
        cropBoxResizable: false,
        zoomOnWheel: false,
        wheelZoomRatio: 1,
        aspectRatio: this.$store.state.crop.aspectRatio,
        responsive: true,
        maxCropBoxWidth: this.$store.state.crop.maxWidth,
        maxCropBoxHeight: this.$store.state.crop.maxHeight,
      };
      this.cropper = new Cropper(this.$refs.source, params);
    },
  },
};
</script>

<style lang="scss">
  .image-cover{
    border: 1px solid rgba(120,141,180,.3);
    width: 100%;
    height: 200px;
  }
  .cropper-container{
    width: 100% !important;
  }
</style>
