<template>
  <div :id="modalId" :class="classes" :style="this.styles" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('sms.send-msg.tabs.groups.title')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <groups-table isSendPage="true"/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hide">{{$t('generic-str.cancel')}}</button>
          <button type="button" class="btn btn-success" @click="confirm">{{$t('generic-str.add')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import GroupsTable from '@/components/groups/GroupTable.vue';

export default {
  components: {
    GroupsTable,
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      selectedGroups: [],
    };
  },
  methods: {
    selectedGroupsChange(param) {
      this.selectedGroups = param.selectedGroups;
    },
    confirm() {
      if (this.selectedGroups.length > 0) {
        let valid = true;
        this.selectedGroups.forEach((element) => {
          if (element.contacts_count === 0) {
            valid = false;
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: `O grupo ${element.name} está vazio`,
              type: 'danger',
            });
          }
        });

        if (valid) {
          this.$root.$emit('groups.addonsend', { selectedGroups: this.selectedGroups });
          this.hide();
        }
      } else {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Por favor, escolha um grupo',
          type: 'danger',
        });
      }
    },
    refreshSelected(param) {
      this.selectedGroups = param.selectedGroups;
    },
  },
  mounted() {
    this.$root.$on('groups.selected', this.selectedGroupsChange);
    this.$root.$on('groups.selected.refresh', this.refreshSelected);
  },
};
</script>
