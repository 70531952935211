<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('generic-str.new-group')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="create" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$tc('generic-str.name', 1)}}</label
                >
                <div class="col-md-6">
                  <input v-model="form.name" type="text" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >{{$t('departments.data-infos.lbl-description')}}</label
                >
                <div class="col-md-6">
                  <textarea
                    v-model="form.description"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.create')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ContactService from '@/services/contact.service';
import Modal from '@/mixins/Modal';

export default {
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      form: {
        name: '',
        description: '',
      },
      isSending: false,
    };
  },
  methods: {
    create() {
      this.isSending = true;
      ContactService.createGroup(this.form).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('generic-str.created-group'),
            type: 'success',
          });
          this.$emit('sent');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>
